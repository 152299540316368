.custom-navbar {
    position: relative; /* Keep the navbar layout consistent */
}

.navbar-collapse {
    width: 100%; /* Make sure it occupies the full width */
}

.navbar-nav {
    display: flex; /* Display items in a row on larger screens */
}

.nav-item {
    margin: 0 10px; /* Optional: add some spacing between items */
}
.custom-toggler {
    background-color: #007bff; /* Arka plan rengini mavi veya istediğiniz başka bir renk yapın */
    border: none;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%23007bff' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    /* İkon rengini mavi yapmak için #007bff kullanıldı */
}


@media (max-width: 767.98px) {
    .navbar-collapse {
        position: absolute; /* Allows the nav items to appear below */
        top: 100%; /* Position it directly below the navbar */
        left: 0; /* Align it to the left */
        color: #0e0546; /* Match the navbar color */
        z-index: 1000; /* Ensure it's above other elements */
    }

    .flex-column {
        flex-direction: column; /* Stack items vertically on smaller screens */
        align-items: center; /* Center align on smaller screens */
    }
}

.custom-navbar {
    background-color : white;
    font-size: large;
    color: #0e0546; /* Set your custom color here */
    border-bottom:5px solid #0e0546;
}
Nav .navbar-link {
    color: #0e0546; /* Normal durumda beyaz renk */
    transition: color 0.3s ease; 
}

Nav .navbar-link:hover {
    color: #74b0fd; /* Hover durumunda değişecek renk */
}